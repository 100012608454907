export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_SWIPER_IMAGE = "CREATE_SWIPER_IMAGE";
export const SORT_SWIPER_IMAGE = "SORT_SWIPER_IMAGE";
export const UPDATE_SWIPER_IMAGE = "UPDATE_SWIPER_IMAGE";
export const DELETE_SWIPER_IMAGE = "DELETE_SWIPER_IMAGE";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_SWIPER_IMAGE](context, params) {
    return ApiService.post('swiper_images', params)
      .then(({ data }) => {
        return data;
      });
  },

  [UPDATE_SWIPER_IMAGE](context, data) {
    return ApiService.post(`swiper_images/${data.id}/update`, data.params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        return data;
      });
  },

  [DELETE_SWIPER_IMAGE](context, id) {
    return ApiService.delete(`swiper_images/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

  [SORT_SWIPER_IMAGE](context, params) {
    return ApiService.post('swiper_images/sort', params)
      .then(({ data }) => {
        return data;
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
