export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_POWER_BRAND_ITEM = "CREATE_POWER_BRAND_ITEM";
export const SORT_POWER_BRAND_ITEMS = "SORT_POWER_BRAND_ITEMS";
export const UPDATE_POWER_BRAND_ITEM = "UPDATE_POWER_BRAND_ITEM";
export const DELETE_POWER_BRAND_ITEM = "DELETE_POWER_BRAND_ITEM";

export const SET_ERROR = "setError";

const actions = {

    [CREATE_POWER_BRAND_ITEM](context, params) {
        return ApiService.post('power_brands', params)
            .then(({ data }) => {
                return data;
            });
    },

    [UPDATE_POWER_BRAND_ITEM](context, data) {
        return ApiService.post(`power_brands/${data.id}/update`, data.params, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(({ data }) => {
                return data;
            });
    },

    [DELETE_POWER_BRAND_ITEM](context, id) {
        return ApiService.delete(`power_brands/${id}`)
            .then(({ data }) => {
                return data;
            });
    },

    [SORT_POWER_BRAND_ITEMS](context, params) {
        return ApiService.post('power_brands/sort', params)
            .then(({ data }) => {
                return data;
            });
    },

};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    actions,
    mutations
};
