export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_GALLERY_IMAGE = "CREATE_GALLERY_IMAGE";
export const SORT_GALLERY_IMAGE = "SORT_GALLERY_IMAGE";
export const UPDATE_GALLERY_IMAGE = "UPDATE_GALLERY_IMAGE";
export const DELETE_GALLERY_IMAGE = "DELETE_GALLERY_IMAGE";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_GALLERY_IMAGE](context, params) {
    return ApiService.post('gallery_images', params)
      .then(({ data }) => {
        return data;
      });
  },

  [UPDATE_GALLERY_IMAGE](context, data) {
    return ApiService.post(`gallery_images/${data.id}/update`, data.params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        return data;
      });
  },

  [DELETE_GALLERY_IMAGE](context, id) {
    return ApiService.delete(`gallery_images/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

  [SORT_GALLERY_IMAGE](context, params) {
    return ApiService.post('gallery_images/sort', params)
      .then(({ data }) => {
        return data;
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
