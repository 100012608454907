const ID_AUTH_KEY = "auth";

export const getUser = () => {
  return window.localStorage.getItem('user');
};

export const saveUser = user => {
  window.localStorage.setItem('user', user);
};

export const destroyAuth = () => {
  window.localStorage.removeItem(ID_AUTH_KEY);
};

export const getAuth = () => {
  return window.localStorage.getItem(ID_AUTH_KEY);
};

export const saveAuth = auth => {
  window.localStorage.setItem(ID_AUTH_KEY, auth);
};

export default { getUser, saveUser, destroyAuth, getAuth, saveAuth };
