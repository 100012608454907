export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_IMAGE_BLOCK = "CREATE_IMAGE_BLOCK";
export const DELETE_IMAGE_BLOCK = "DELETE_IMAGE_BLOCK";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_IMAGE_BLOCK](context, params) {
    return ApiService.post(`blocks`, params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        return data;
      });
  },


  [DELETE_IMAGE_BLOCK](context, id) {
    return ApiService.delete(`blocks/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
