import ApiService from "@/core/services/api.service";

// action types
export const SHOWCASE_DETAIL = "SHOWCASE_DETAIL";
export const UPDATE_SHOWCASE = "UPDATE_SHOWCASE";

export const SET_ERROR = "setError";

const state = {

};

const actions = {
    [SHOWCASE_DETAIL]() {
        return ApiService.get(`pages/find_by_path/showcase`).then(({ data }) => {
            return data;
        });
    },

    [UPDATE_SHOWCASE](context, { id, form }) {
        return ApiService.upload(`showcases/${id}/update`, form)
            .then(({ data }) => {
                return data;
            });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations
};
