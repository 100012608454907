import ApiService from "@/core/services/api.service";

// action types
export const SUBCHAPTERS = "SUBCHAPTERS";
export const CREATE_SUBCHAPTER = "CREATE_SUBCHAPTER";
export const UPDATE_SUBCHAPTER = "UPDATE_SUBCHAPTER";
export const DELETE_SUBCHAPTER = "DELETE_SUBCHAPTER";
export const SUBCHAPTER_DETAIL = "SUBCHAPTER_DETAIL";

export const SET_ERROR = "setError";

const state = {

};

const actions = {
  [SUBCHAPTERS](context, params) {
    var url = `subchapters?page=${params.page}&per_page=${params.per_page}`;
    return ApiService.query(url).then(({ data }) => {
      return data;
    });
  },

  [SUBCHAPTER_DETAIL](context, { id }) {
    return ApiService.get(`subchapters`, id).then(({ data }) => {
      return data;
    });
  },

  [CREATE_SUBCHAPTER](context, params) {
    return ApiService.post("subchapters", params).then(({ data }) => {
      return data;
    });
  },

  [UPDATE_SUBCHAPTER](context, params) {
    return ApiService.put(`subchapters/${params.subchapter.id}`, params).then(
      ({ data }) => {
        return data;
      }
    );
  },

  [DELETE_SUBCHAPTER](context, item) {
    return ApiService.delete(`subchapters/${item.subchapter.id}`).then(({ data }) => {
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations
};
