import ApiService from "@/core/services/api.service";

// action types
export const CREATE_MANSORY_IMAGE = "CREATE_MANSORY_IMAGE";
export const SORT_MANSORY_IMAGE = "SORT_MANSORY_IMAGE";
export const UPDATE_MANSORY_IMAGE = "UPDATE_MANSORY_IMAGE";
export const DELETE_MANSORY_IMAGE = "DELETE_MANSORY_IMAGE";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_MANSORY_IMAGE](context, params) {

    return ApiService.post('mansory_images', params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        return data;
      });
  },

  [UPDATE_MANSORY_IMAGE](context, data) {
    return ApiService.post(`mansory_images/${data.id}/update`, data.params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        return data;
      });
  },

  [DELETE_MANSORY_IMAGE](context, id) {
    return ApiService.delete(`mansory_images/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

  [SORT_MANSORY_IMAGE](context, params) {
    return ApiService.post('mansory_images/sort', params)
      .then(({ data }) => {
        return data;
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
