import ApiService from "@/core/services/api.service";

// action types
export const CREATE_IMAGE = "CREATE_IMAGE";
export const UPDATE_IMAGE = "UPDATE_IMAGE";
export const DELETE_IMAGE = "DELETE_IMAGE";

export const SET_ERROR = "setError";

const state = {
};

const actions = {
    [CREATE_IMAGE](context, params) {
        return ApiService.upload("images", params).then(({ data }) => {
            return data;
        });
    },
    [UPDATE_IMAGE](context, { id, body }) {
        return ApiService.post(`images/${id}/update`, body).then(({ data }) => {
            return data;
        });
    },
    [DELETE_IMAGE](context, { id }) {
        return ApiService.delete(`images/${id}`).then(({ data }) => {
            return data;
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations
};
