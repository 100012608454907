import ApiService from "@/core/services/api.service";


// action types
export const BRAND_ASSETS = "BRAND_ASSETS";
export const CREATE_BRAND_ASSETS = "CREATE_BRAND_ASSETS";
export const UPDATE_BRAND_ASSETS = "UPDATE_BRAND_ASSETS";
export const DELETE_BRAND_ASSETS = "DELETE_BRAND_ASSETS";
export const BRAND_ASSETS_DETAIL = "BRAND_ASSETS_DETAIL";

// mutation types

export const SET_ERROR = "setError";


const actions = {
  [BRAND_ASSETS]() {
    var url = `pages/brand-assets`;

    return ApiService.query(url).then(({ data }) => {
      return data;
    });
  },

  [BRAND_ASSETS_DETAIL]() {
    return ApiService.get(`pages/find_by_path/brand-assets`).then(({ data }) => {
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  actions,
  mutations
};
