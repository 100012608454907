import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
//import JwtService from "@/core/services/jwt.service";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import CKEditor from '@ckeditor/ckeditor5-vue2';
//import { pick } from 'lodash'

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import 'leaflet/dist/leaflet.css';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import AdUtils from '@/core/plugins/ad-utils.js';
//import DecoupledEditor from '@ckeditor/ckeditor5-editor-decoupled';
Vue.use(AdUtils);

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.use( CKEditor );
Vue.use(VueMoment, {
  moment,
})

Vue.prototype.$http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error && router.currentRoute.name !== 'signin' && (error.response.status === 401 || error.response.status === 403)) {
      localStorage.clear();
      router.push({ name: 'login' })
    }
    return Promise.reject(error)
  }
)



new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
