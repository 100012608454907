import ApiService from "@/core/services/api.service";

// action types
export const HOMEPAGES = "HOMEPAGES";
export const CREATE_HOMEPAGE = "CREATE_HOMEPAGE";
export const UPDATE_HOMEPAGE = "UPDATE_HOMEPAGE";

export const SET_ERROR = "setError";

const state = {
};

const actions = {
    [HOMEPAGES]() {
        return ApiService.post("pages/find_by_path", { path: '/' })
            .then(({ data }) => {
                return data;
            });
    },

    [CREATE_HOMEPAGE](context, params) {
        return ApiService.post("home_pages", params)
            .then(({ data }) => {
                return data;
            });
    },

    [UPDATE_HOMEPAGE](context, params) {
        return ApiService.put(`home_pages/${params.id}`, params)
            .then(({ data }) => {
                return data;
            });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations
};
