import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import it from 'vuetify/es5/locale/it'
Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { it },
    current: 'it',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#3F4254",
        secondary: "#e8ecfa",
        accent: "#5d78ff",
        error: "#f35050",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822"
      }
    }
  }
});
