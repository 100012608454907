import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/pages/home",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/homepage",
          name: "homepage",
          component: () =>
            import("@/view/pages/homepage/Homepage.vue")
        },
        {
          path: "/terms-and-conditions",
          name: "terms-and-conditions",
          component: () =>
            import("@/view/pages/termsAndConditions/TermsAndConditions.vue")
        },
        {
          path: "/brand-assets",
          name: "brand-assets",
          component: () => import("@/view/pages/brand-assets/BrandAssets.vue")
        },

        {
          path: "/power-brands",
          name: "power-brands",
          component: () => import("@/view/pages/power-brands/PowerBrands.vue")
        },
        {
          path: "/modifica-power-brand/:id/:name",
          name: "modifica-power-brand",
          component: () => import("@/view/pages/power-brands/PowerBrand.vue")
        },
        {
          path: "/showcase",
          name: "showcase",
          component: () => import("@/view/pages/showcase/Showcase.vue")
        },
        {
          path: "/pages",
          name: "/pages",
          component: () => import("@/view/pages/pages/Form.vue")
        },
        {
          path: "/pages/:path",
          name: "pages-by-path",
          component: () =>
            import("@/view/pages/pages-by-path/Page.vue")
        },
        {
          path: "/modifica-capitolo/:id/:name",
          name: "modifica-capitolo",
          component: () => import("@/view/pages/chapters/Form.vue"),
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
