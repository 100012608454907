import ApiService from "@/core/services/api.service";

// action types
export const PAGES = "PAGES";
export const PAGES_DETAIL = "PAGES_DETAIL";
export const UPDATE_PAGES = "UPDATE_PAGES";
export const FIND_BY_PATH = "FIND_BY_PATH";

// mutation types
// export const SET_closingS = "setbrand_assets";
export const SET_ERROR = "setError";

const state = {

};

const actions = {
  [PAGES_DETAIL](context, { id }) {
    return ApiService.get(`pages/${id}`).then(
      ({ data }) => {
        return data;
      }
    );
  },

  [UPDATE_PAGES](context, { id, data }) {
    return ApiService.put(`pages/${id}`, data).then(
      ({ data }) => {
        return data;
      }
    );
  },

  [FIND_BY_PATH](context, { path }) {
    return ApiService.get(`pages/find_by_path/${path}`).then(
      ({ data }) => {
        return data;
      }
    );
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations
};
