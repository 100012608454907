
/*eslint no-unused-vars: ["error", { "args": "none" }]*/
import { serialize } from 'object-to-formdata';

export default {
    install(Vue, options) {
        Vue.prototype.jsonToFormData = (data) => {
            return serialize(
                data,
                {
                    indices: true,
                    nullsAsUndefineds: false,
                    booleansAsIntegers: false,
                    allowEmptyArrays: true,
                },
            );
        };


    }
};

