import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {


  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;
    // Vue.axios.interceptors.request.use((config) => {
    //   config = this.setHeader(config);
    //   return config;
    // }, (error) => {
    //   // Do something with request error
    //   return Promise.reject(error);
    // });
    // axios.interceptors.response.use(
    //   (response) => {
    //     response = this.parseResponse(response);
    //     return response;
    //   },
    //   (error) => {
    //     return Promise.reject(error);
    //   },
    // );
  },


  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    // Vue.axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Token ${JwtService.getToken()}`;
    var auth = JSON.parse(JwtService.getAuth());
    Vue.axios.defaults.headers.common["access-token"] = auth["access-token"];
    Vue.axios.defaults.headers.common["client"] = auth["client"];
    Vue.axios.defaults.headers.common["uid"] = auth["uid"];
    Vue.axios.defaults.headers.common["token-type"] = auth["token-type"];
  },


  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    this.setHeader();
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  /**
   * Set the UPLOAD POST HTTP request
   * @param resource
   * @param form
   * @returns {*}
   */
  upload(resource, formData) {
    return Vue.axios.post(`${resource}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
  },

};

export default ApiService;
