import ApiService from "@/core/services/api.service";

// action types
export const TERM_DETAIL = "TERM_DETAIL";

export const SET_ERROR = "setError";

const state = {

};

const actions = {

  [TERM_DETAIL]() {
    return ApiService.get(`pages/find_by_path/terms-and-conditions`).then(({ data }) => {
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations
};
