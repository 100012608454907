export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_TEXT_BLOCK = "CREATE_TEXT_BLOCK";
export const UPDATE_TEXT_BLOCK = "UPDATE_TEXT_BLOCK";
export const DELETE_TEXT_BLOCK = "DELETE_TEXT_BLOCK";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_TEXT_BLOCK](context, params) {
    return ApiService.post('blocks', params)
      .then(({ data }) => {
        return data;
      });
  },

  [UPDATE_TEXT_BLOCK](context, { id, params }) {
    return ApiService.put(`blocks/${id}`, params)
      .then(({ data }) => {
        return data;
      });
  },

  [DELETE_TEXT_BLOCK](context, id) {
    return ApiService.delete(`blocks/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
