export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_RANGE = "UPDATE_RANGE";


export const SET_ERROR = "setError";

const actions = {

  [UPDATE_RANGE](context, data) {

    return ApiService.post(`range_items/${data.id}/update`, data.params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        return data;
      });
  },


};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
