export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_CARD_ITEM = "CREATE_CARD_ITEM";
export const SORT_CARD_ITEMS  = "SORT_CARD_ITEMS";
export const UPDATE_CARD_ITEM = "UPDATE_CARD_ITEM";
export const DELETE_CARD_ITEM = "DELETE_CARD_ITEM";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_CARD_ITEM](context, params) {
    return ApiService.post('cards', params)
      .then(({ data }) => {
        return data;
      });
  },

  [UPDATE_CARD_ITEM](context, data) {
    return ApiService.put(`cards/${data.id}`, data.params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        return data;
      });
  },

  [DELETE_CARD_ITEM](context, id) {
    return ApiService.delete(`cards/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

  [SORT_CARD_ITEMS](context, params) {
    return ApiService.post('cards/sort', params)
      .then(({ data }) => {
        return data;
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
