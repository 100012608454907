import ApiService from "@/core/services/api.service";

// action types
export const CHAPTERS = "CHAPTERS";
export const CREATE_CHAPTER = "CREATE_CHAPTER";
export const UPDATE_CHAPTER = "UPDATE_CHAPTER";
export const DELETE_CHAPTER = "DELETE_CHAPTER";
export const CHAPTER_DETAIL = "CHAPTER_DETAIL";

export const SET_ERROR = "setError";
export const SET_CREATABLE = "SET_CREATABLE";
export const SET_DELETABLE = "SET_DELETABLE";

const state = {
  creatable: true,
  deletable: true,
};

const actions = {
  [CHAPTERS](context, params) {
    var url = `chapters?page=${params.page}&per_page=${params.per_page}`;
    if (params.brand_asset_id) {
      url += `&brand_asset_id=${params.brand_asset_id}`;
    }
    return ApiService.query(url).then(({ data }) => {
      return data;
    });
  },

  [CHAPTER_DETAIL](context, { id }) {
    return ApiService.get(`chapters`, id).then(({ data }) => {
      return data;
    });
  },

  [CREATE_CHAPTER](context, params) {
    return ApiService.post("chapters", params).then(({ data }) => {
      return data;
    });
  },

  [UPDATE_CHAPTER](context, params) {
    return ApiService.put(`chapters/${params.chapter.id}`, params).then(
      ({ data }) => {
        return data;
      }
    );
  },

  [DELETE_CHAPTER](context, id) {

    return ApiService.delete(`chapters/${id}`).then(({ data }) => {
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_CREATABLE](state, value) {
    state.creatable = value;
  },
  [SET_DELETABLE](state, value) {
    state.deletable = value;
  },
};

export default {
  state,
  actions,
  mutations
};
