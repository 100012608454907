export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_POWER_BRAND_BLOCK = "CREATE_POWER_BRAND_BLOCK";
export const DELETE_POWER_BRAND_BLOCK = "DELETE_POWER_BRAND_BLOCK";

export const SET_ERROR = "setError";

const actions = {

    [CREATE_POWER_BRAND_BLOCK](context, params) {
        return ApiService.post('blocks', params)
            .then(({ data }) => {
                return data;
            });
    },


    [DELETE_POWER_BRAND_BLOCK](context, id) {
        return ApiService.delete(`blocks/${id}`)
            .then(({ data }) => {
                return data;
            });
    },

};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    actions,
    mutations
};
