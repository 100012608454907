export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_CARD_BLOCK = "CREATE_CARD_BLOCK";
export const DELETE_CARD_BLOCK = "DELETE_CARD_BLOCK";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_CARD_BLOCK](context, params) {
    return ApiService.post('blocks', params)
      .then(({ data }) => {
        return data;
      });
  },


  [DELETE_CARD_BLOCK](context, id) {
    return ApiService.delete(`blocks/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
