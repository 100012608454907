import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
// import breadcrumbs from "./breadcrumbs.module";
import brandassets from "./brand_assets.module";
import power_brands from "./power_brands.module";
import chapters from "./chapters.module";
import subchapters from "./subchapters.module";
import snackbar from "./snackbar.module";
import blocks from "./blocks.module";
import gallery_blocks from "./gallery_blocks.module";
import gallery_images from "./gallery_images.module";
import swiper_blocks from "./swiper_blocks.module";
import swiper_images from "./swiper_images.module";
import product_blocks from "./product_blocks.module";
import card_blocks from "./card_blocks.module";
import asset_blocks from "./asset_blocks.module";
import products from "./products.module";
import color_blocks from "./color_blocks.module";
import colors from "./colors.module";
import range_blocks from "./range_blocks.module";
import range from "./range.module";
import text_blocks from "./text_blocks.module";
import mansory_blocks from "./mansory_blocks.module";
import pages from "./pages.module";
import terms_conditions from "./terms_conditions.module";
import showcases from "./showcases.module";
import attachments from "./attachments.module";
import images from "./images.module";
import homepages from "./homepages.module";
import card_items from "./card_items.module";
import asset_items from "./asset_items.module";
import image_blocks from "./image_blocks.module";
import power_brand_blocks from "./power_brand_blocks.module";
import power_brand_items from "./power_brand_items.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    power_brands,
    // breadcrumbs,
    brandassets,
    snackbar,
    chapters,
    subchapters,
    blocks,
    gallery_blocks,
    text_blocks,
    gallery_images,
    swiper_blocks,
    swiper_images,
    product_blocks,
    card_blocks,
    asset_blocks,
    products,
    colors,
    color_blocks,
    range_blocks,
    range,
    mansory_blocks,
    terms_conditions,
    showcases,
    attachments,
    images,
    homepages,
    pages,
    card_items,
    asset_items,
    image_blocks,
    power_brand_blocks,
    power_brand_items,
  },
  plugins: [createPersistedState()]
});
