import ApiService from "@/core/services/api.service";

// action types
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";

export const SET_ERROR = "setError";

const state = {

};

const actions = {
    [DELETE_ATTACHMENT](context, item) {
        return ApiService.delete(`attachments/${item.id}`).then(({ data }) => {
            return data;
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    }
};

export default {
    state,
    actions,
    mutations
};
