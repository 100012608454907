export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const SORT_PRODUCT = "SORT_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_PRODUCT](context, params) {
    return ApiService.post('products', params)
      .then(({ data }) => {
        return data;
      });
  },

  [UPDATE_PRODUCT](context, data) {
    return ApiService.post(`products/${data.id}/update`, data.params, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(({ data }) => {
        return data;
      });
  },

  [DELETE_PRODUCT](context, id) {
    return ApiService.delete(`products/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

  [SORT_PRODUCT](context, params) {
    return ApiService.post('products/sort', params)
      .then(({ data }) => {
        return data;
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
