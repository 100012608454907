import ApiService from "@/core/services/api.service";

// action types
export const BLOCKS = "BLOCKS";
export const CREATE_BLOCK = "CREATE_BLOCK";
export const UPDATE_BLOCK = "UPDATE_BLOCK";
export const DELETE_BLOCK = "DELETE_BLOCK";
export const BLOCK_DETAIL = "BLOCK_DETAIL";
export const BLOCK_INIT = "BLOCK_INIT";
export const UPDATE_BLOCK_PRODUCTS = "UPDATE_BLOCK_PRODUCTS";

export const UPDATE_TEXT_BLOCK = "UPDATE_TEXT_BLOCK";
export const SET_ERROR = "setError";

const state = {

};

const actions = {
  [BLOCKS](context, params) {
    var url = `blocks?page=${params.page}&per_page=${params.per_page}`;
    return ApiService.query(url).then(({ data }) => {
      return data;
    });
  },

  [BLOCK_INIT](context, { id }) {
    return ApiService.get(`blocks/init`, id).then(({ data }) => {
      return data;
    });
  },


  [BLOCK_DETAIL](context, { id }) {
    return ApiService.get(`blocks`, id).then(({ data }) => {
      return data;
    });
  },

  [CREATE_BLOCK](context, params) {
    return ApiService.post("blocks", params).then(({ data }) => {
      return data;
    });
  },

  [UPDATE_BLOCK](context, params) {
    return ApiService.put(`blocks/${params.id}`, params.data).then(
      ({ data }) => {
        return data;
      }
    );
  },

  [DELETE_BLOCK](context, item) {
    return ApiService.delete(`blocks/${item.block.id}`).then(({ data }) => {
      return data;
    });
  },

  [UPDATE_BLOCK_PRODUCTS](context, { id, body }) {
    return ApiService.put(`blocks/${id}`, body)
      .then(({ data }) => {
        return data;
      });
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations
};
