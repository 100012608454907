export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_COLOR = "CREATE_COLOR";
export const SORT_COLOR = "SORT_COLOR";
export const UPDATE_COLOR = "UPDATE_COLOR";
export const DELETE_COLOR = "DELETE_COLOR";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_COLOR](context, params) {
    return ApiService.post('colors', params)
      .then(({ data }) => {
        return data;
      });
  },

  [UPDATE_COLOR](context, data) {
    return ApiService.put(`colors/${data.id}`, data.params)
      .then(({ data }) => {
        return data;
      });
  },

  [DELETE_COLOR](context, id) {
    return ApiService.delete(`colors/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

  [SORT_COLOR](context, params) {
    return ApiService.post('colors/sort', params)
      .then(({ data }) => {
        return data;
      });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
