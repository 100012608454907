import ApiService from "@/core/services/api.service";


// action types
export const POWER_BRANDS = "POWER_BRANDS";
export const POWER_BRAND_DETAIL = "POWER_BRANDS_DETAIL";

// mutation types

export const SET_ERROR = "setError";


const actions = {
  [POWER_BRANDS]() {
    var url = `pages/power-brands`;

    return ApiService.query(url).then(({ data }) => {
      return data;
    });
  },

  [POWER_BRAND_DETAIL](context, id) {
    return ApiService.get(`pages/${id}`).then(({ data }) => {
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
};

export default {
  actions,
  mutations
};
