export const namespaced = true
import ApiService from "@/core/services/api.service";

// action types
export const CREATE_GALLERY_BLOCK = "CREATE_GALLERY_BLOCK";
export const UPDATE_GALLERY_BLOCK = "UPDATE_GALLERY_BLOCK";
export const DELETE_GALLERY_BLOCK = "DELETE_GALLERY_BLOCK";

export const SET_ERROR = "setError";

const actions = {

  [CREATE_GALLERY_BLOCK](context, params) {
    return ApiService.post('blocks', params)
      .then(({ data }) => {
        return data;
      });
  },

  [UPDATE_GALLERY_BLOCK](context, data) {
    return ApiService.put(`blocks/${data.id}`, data.params)
      .then(({ data }) => {
        return data;
      });
  },

  [DELETE_GALLERY_BLOCK](context, id) {
    return ApiService.delete(`blocks/${id}`)
      .then(({ data }) => {
        return data;
      });
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  actions,
  mutations
};
